<template>
    <div class="connection-bigBox">
        <div class="banner">
            <img src="../assets/images/connection/banner.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/">联系我们   </a>
            </div>
        </div>
        <div class="connection">
            <div class="connection-content">
                <div class="phone-style">
                    <div class="phone-left">
                        <div class="title">
                          湖北省制造业与互联网融合发展联盟<br/> 
						  湖北省中小企业数字化转型发展联盟
                        </div>
                        <div class="title">——</div>
                        <div class="ewm">
							   <img v-if="!lxwm.ewm" src="../assets/images/connection/ewm.png" > 
                            <img v-else :src="lxwm.ewm" alt="">
                            <div>
                                <p>电话：{{lxwm.dh}}</p>
                                <p>邮政编码：{{lxwm.yzbm}}</p>
                                <p>邮箱：{{lxwm.yx}}</p>
                                <p>地址：{{lxwm.dz}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="phone-right">
                        <p class="service-hotline">服务热线</p>
                        <p class="number">{{lxwm.fwrx}}</p>
                        <p  class="number">——</p>
                        <p class="service-hotline">加盟合作</p>
                        <p class="number">{{lxwm.jmhz}}</p>
                      
                        <p class="service-time">服务时间：{{lxwm.fwsj}}</p>
                    </div>
                </div>
				  <img v-if="!lxwm.dt" src="../assets/images/connection/dt.png" > 
                <img v-else :src="lxwm.dt" alt="">
            </div>
        </div>
       <div>
       
       </div>
    </div>
</template>

<script>
	import request from "../utils/request.js";
		  import { apiUrl ,fileUrl} from '@/config'
	import Swiper from "swiper";
	import { setTimeout } from "timers";
export default {
    data(){
        return {
			fileUrl:fileUrl,
			lxwm:{
				gsmc:'',
				dh:'',
				dz:'',
				yx:'',
				yzbm:'',
				ewm:'',
				fwrx:'',
				fwsj:'',
				jmhz:'',
				dt:''
			}
        }
    },
    mounted(){
         this.getlxwm()
    },
	methods:{
		/**联系我们详情**/
		getlxwm(){
			var that=this
			var queryId=599
			var dataId=1
			request.get('/formMake/view/'+queryId+'/'+dataId).then(res =>{
				if(res.code==0){
					const {data}=res.data
					that.lxwm=data.editMData
					
					if ((data && data.editMData && data.editMData.ewm && data.editMData.ewm.length > 0) ||( data && data.editMData && data.editMData.dt && data.editMData.dt.length > 0)) {  
					    // 获取第一个图片的URL  
					    var ewm = data.editMData.ewm[0].url;  
					    var dt = data.editMData.dt[0].url;  
					    // 将URL赋值给that.lxwm.img  
					    that.lxwm.ewm = this.fileUrl+ewm;  
						 that.lxwm.dt = this.fileUrl+dt;  
					} else {  
						
					    console.error("No image URL found in the data.");  
					}
					
				}
			})
		
		
		},
	}
}
</script>
<style lang="scss" scoped>
.connection-bigBox{
    box-sizing: border-box;
    .banner{
        width: 100%;   
        height: 300px;
        .bimg{
            width: 100%;
            height:100%;
        } 
    }
    .orientation{
        background-color: #f5f5f5;
        height: 60px;   
        .orientation-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            line-height: 60px;
            color:#666666
        }
    }
    .connection{
        background: white;
        margin-bottom: 20px;
        .connection-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .phone-style{
                background: #f5f5f5;
                display: flex;
                justify-content: space-between;
                margin: 20px 0px;
                padding: 50px 20px;
                .phone-left{
                    width: 30%;
                    .title{
                        font-size: 20px;
                        font-weight: bold;
                        color: #4781da;
                    }
                    .ewm{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 30px;
                        line-height: 30px;
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
                .phone-right{
                    width: 30%;
                    background-color: #0857d0;
                    color: white;
                    padding: 30px 20px;
                    line-height: 30px;
                    font-weight: bold;
                    .service-hotline{
                        font-size: 15px ;
                    }
                    .number{
                        font-size: 20px;
                    }
                    .service-time{
                        font-size: 15px ;
                        margin-top: 18px;
                    }
                }
            }
        }
    }
}
</style>
